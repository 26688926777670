import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_USERS_STAGING,
	FETCH_USERS_STAGING_ERR,
	ENABLE_USER_STAGING,
	ENABLE_USER_STAGING_ERR,
	DISABLE_USER_STAGING,
	DISABLE_USER_STAGING_ERR,
	DELETE_USER_STAGING,
	DELETE_USER_STAGING_ERR,
	REMOVE_USER_FROM_GROUP_STAGING,
	REMOVE_USER_FROM_GROUP_STAGING_ERR,
	ADD_USER_TO_GROUP_STAGING,
	ADD_USER_TO_GROUP_STAGING_ERR,
	FETCH_USERS_PRODUCTION,
	FETCH_USERS_PRODUCTION_ERR,
	ENABLE_USER_PRODUCTION,
	ENABLE_USER_PRODUCTION_ERR,
	DISABLE_USER_PRODUCTION,
	DISABLE_USER_PRODUCTION_ERR,
	DELETE_USER_PRODUCTION,
	DELETE_USER_PRODUCTION_ERR,
	REMOVE_USER_FROM_GROUP_PRODUCTION,
	REMOVE_USER_FROM_GROUP_PRODUCTION_ERR,
	ADD_USER_TO_GROUP_PRODUCTION,
	ADD_USER_TO_GROUP_PRODUCTION_ERR,
	CLEAR_USER_PROCESS,
	getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const fetchUsersStaging = () => async (dispatch) => {
	const request = API.get(
		'savant-admin-api',
		config.apiEndpoints.usersStaging,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USERS_STAGING,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USERS_STAGING_ERR,
				payload: err
			})
	);
};

export const fetchUsersProduction = () => async (dispatch) => {
	const request = API.get(
		'savant-admin-api',
		config.apiEndpoints.usersProduction,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USERS_PRODUCTION,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USERS_PRODUCTION_ERR,
				payload: err
			})
	);
};

export const approveUserStaging = async ({ username, approve, metal }) => {
	try {
		const response = await API.patch(
			'savant-admin-api',
			replacer(config.apiEndpoints.approveUserStaging, {
				username,
				approve,
				metal
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		toastr.info(response.message);
	} catch (err) {
		const innerMsg = err?.response?.data?.message;
		const message = `${err.message}. ${innerMsg ? innerMsg + '.' : ''}`;
		toastr.error(message);
	}
};

export const approveUserProduction = async ({
	username,
	approve,
	metal = ''
}) => {
	try {
		const response = await API.patch(
			'savant-admin-api',
			replacer(config.apiEndpoints.approveUserProduction, {
				username,
				approve,
				metal
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		toastr.info(response.message);
	} catch (err) {
		const innerMsg = err?.response?.data?.message;
		const message = `${err.message}. ${innerMsg ? innerMsg + '.' : ''}`;
		toastr.error(message);
	}
};

export const disableUserStaging = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.patch(
		'savant-admin-api',
		replacer(config.apiEndpoints.disableUserStaging, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DISABLE_USER_STAGING,
				payload: response[0]
			});
			dispatch(fetchUsersStaging());
			toastr.success('Disable User', 'User disabled successfully');
		},
		(err) => {
			dispatch({
				type: DISABLE_USER_STAGING_ERR,
				payload: err
			});
			toastr.error('Disable User', 'There was an error disabling the user');
		}
	);
};

export const disableUserProduction = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.patch(
		'savant-admin-api',
		replacer(config.apiEndpoints.disableUserProduction, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DISABLE_USER_PRODUCTION,
				payload: response[0]
			});
			dispatch(fetchUsersProduction());
			toastr.success('Disable User', 'User disabled successfully');
		},
		(err) => {
			dispatch({
				type: DISABLE_USER_PRODUCTION_ERR,
				payload: err
			});
			toastr.error('Disable User', 'There was an error disabling the user');
		}
	);
};

export const enableUserStaging = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.patch(
		'savant-admin-api',
		replacer(config.apiEndpoints.enableUserStaging, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: ENABLE_USER_STAGING,
				payload: response[0]
			});
			dispatch(fetchUsersStaging());
			toastr.success('Enable User', 'User enabled successfully');
		},
		(err) => {
			dispatch({
				type: ENABLE_USER_STAGING_ERR,
				payload: err
			});
			toastr.error('Enable User', 'There was an error enabling the user');
		}
	);
};

export const enableUserProduction = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.patch(
		'savant-admin-api',
		replacer(config.apiEndpoints.enableUserProduction, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: ENABLE_USER_PRODUCTION,
				payload: response[0]
			});
			dispatch(fetchUsersProduction());
			toastr.success('Enable User', 'User enabled successfully');
		},
		(err) => {
			dispatch({
				type: ENABLE_USER_PRODUCTION_ERR,
				payload: err
			});
			toastr.error('Disable User', 'There was an error enabling the user');
		}
	);
};

export const deleteUserStaging = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.deleteUserStaging, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_USER_STAGING,
				payload: response[0]
			});
			dispatch(fetchUsersStaging());
			toastr.success('Delete User', 'User deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_USER_STAGING_ERR,
				payload: err
			});
			toastr.error('Delete User', 'There was an error deleting the user');
		}
	);
};

export const deleteUserProduction = (payload) => async (dispatch) => {
	const { username } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.deleteUserProduction, {
			username: encodeURI(username)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_USER_PRODUCTION,
				payload: response[0]
			});
			dispatch(fetchUsersProduction());
			toastr.success('Delete User', 'User deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_USER_PRODUCTION_ERR,
				payload: err
			});
			toastr.error('Delete User', 'There was an error deleting the user');
		}
	);
};

export const removeUserSecurityGroupStaging = (payload) => async (dispatch) => {
	dispatch({
		type: CLEAR_USER_PROCESS
	});

	const { username, usergroup } = payload;

	const request = API.del(
		'savant-admin-api',
		replacer(config.apiEndpoints.removeUserFromGroupStaging, {
			username: encodeURI(username),
			groupname: encodeURI(usergroup)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: REMOVE_USER_FROM_GROUP_STAGING,
				payload: response[0]
			});
			dispatch(fetchUsersStaging());
			toastr.success(
				'Remove User from Group',
				'User removed from group successfully'
			);
		},
		(err) => {
			dispatch({
				type: REMOVE_USER_FROM_GROUP_STAGING_ERR,
				payload: err
			});
			toastr.error(
				'Remove User from Group',
				'There was an error removing the user from the group'
			);
		}
	);
};

export const removeUserSecurityGroupProduction =
	(payload) => async (dispatch) => {
		dispatch({
			type: CLEAR_USER_PROCESS
		});

		const { username, usergroup } = payload;

		const request = API.del(
			'savant-admin-api',
			replacer(config.apiEndpoints.removeUserFromGroupProduction, {
				username: encodeURI(username),
				groupname: encodeURI(usergroup)
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: REMOVE_USER_FROM_GROUP_PRODUCTION,
					payload: response[0]
				});
				dispatch(fetchUsersProduction());
				toastr.success(
					'Remove User from Group',
					'User removed from group successfully'
				);
			},
			(err) => {
				dispatch({
					type: REMOVE_USER_FROM_GROUP_PRODUCTION_ERR,
					payload: err
				});
				toastr.error(
					'Remove User from Group',
					'There was an error removing the user from the group'
				);
			}
		);
	};

export const addUserSecurityGroupStaging = (payload) => async (dispatch) => {
	dispatch({
		type: CLEAR_USER_PROCESS
	});

	const { username, usergroup } = payload;

	const request = API.post(
		'savant-admin-api',
		replacer(config.apiEndpoints.addUserToGroupStaging, {
			username: encodeURI(username),
			groupname: encodeURI(usergroup)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: ADD_USER_TO_GROUP_STAGING,
				payload: response[0]
			});
			dispatch(fetchUsersStaging());
			toastr.success('Add User to Group', 'User added to group successfully');
		},
		(err) => {
			dispatch({
				type: ADD_USER_TO_GROUP_STAGING_ERR,
				payload: err
			});
			toastr.error(
				'Add User to Group',
				'There was an error adding the user to the group'
			);
		}
	);
};

export const addUserSecurityGroupProduction = (payload) => async (dispatch) => {
	dispatch({
		type: CLEAR_USER_PROCESS
	});

	const { username, usergroup } = payload;

	const request = API.post(
		'savant-admin-api',
		replacer(config.apiEndpoints.addUserToGroupProduction, {
			username: encodeURI(username),
			groupname: encodeURI(usergroup)
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: ADD_USER_TO_GROUP_PRODUCTION,
				payload: response[0]
			});
			dispatch(fetchUsersProduction());
			toastr.success('Add User to Group', 'User added to group successfully');
		},
		(err) => {
			dispatch({
				type: ADD_USER_TO_GROUP_PRODUCTION_ERR,
				payload: err
			});
			toastr.error(
				'Add User to Group',
				'There was an error adding the user to the group'
			);
		}
	);
};
