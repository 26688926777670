const config = {
	apiGateway: {
		URL: 'https://savant-api-staging.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_g41Ky8J0Z',
		APP_CLIENT_ID: '307meohr1bdmpm7ro3h45rm7nj',
		IDENTITY_POOL_ID: 'eu-west-2:a9b74b87-5104-4b03-bff4-b233a519bcd0'
	},
	idleTimeout: 1000 * 60 * 30
};

const apiEndpoints = {
	regions: '/admin/regions',
	createRegion: '/admin/regions',
	updateRegion: '/admin/regions/${id}', // eslint-disable-line no-template-curly-in-string

	smelters: '/admin/smelters',
	createSmelter: '/admin/smelters',
	updateSmelter: '/admin/smelters/${id}', // eslint-disable-line no-template-curly-in-string
	deleteSmelter: '/admin/smelters/${id}', // eslint-disable-line no-template-curly-in-string

	pseudoGroups: '/admin/pseudogroups',
	createPseudoGroup: '/admin/pseudogroups',
	updatePseudoGroup: '/admin/pseudogroups/${id}', // eslint-disable-line no-template-curly-in-string
	deletePseudoGroup: '/admin/pseudogroups/${id}', // eslint-disable-line no-template-curly-in-string

	pseudoGroupSecurityGroups: '/admin/pseudogroups/${id}/securitygroups', // eslint-disable-line no-template-curly-in-string
	createPseudoGroupSecurityGroup: '/admin/pseudogroups/${id}/securitygroups', // eslint-disable-line no-template-curly-in-string
	updatePseudoGroupSecurityGroup:
		'/admin/pseudogroups/${id}/securitygroups/${securityGroupId}', // eslint-disable-line no-template-curly-in-string
	deletePseudoGroupSecurityGroup:
		'/admin/pseudogroups/${id}/securitygroups/${securityGroupId}', // eslint-disable-line no-template-curly-in-string

	usersStaging: '/admin/users/staging',
	approveUserStaging:
		'/admin/user-access/staging/${username}?approve=${approve}&metal=${metal}', // eslint-disable-line no-template-curly-in-string
	disableUserStaging: '/admin/users/staging/disable/${username}', // eslint-disable-line no-template-curly-in-string
	enableUserStaging: '/admin/users/staging/enable/${username}', // eslint-disable-line no-template-curly-in-string
	addUserToGroupStaging: '/admin/users/staging/${username}/group/${groupname}', // eslint-disable-line no-template-curly-in-string
	removeUserFromGroupStaging:
		'/admin/users/staging/${username}/group/${groupname}', // eslint-disable-line no-template-curly-in-string
	deleteUserStaging: '/admin/users/staging/${username}', // eslint-disable-line no-template-curly-in-string
	usersProduction: '/admin/users/production',
	approveUserProduction:
		'/admin/user-access/production/${username}?approve=${approve}&metal=${metal}', // eslint-disable-line no-template-curly-in-string
	disableUserProduction: '/admin/users/production/disable/${username}', // eslint-disable-line no-template-curly-in-string
	enableUserProduction: '/admin/users/production/enable/${username}', // eslint-disable-line no-template-curly-in-string
	addUserToGroupProduction:
		'/admin/users/production/${username}/group/${groupname}', // eslint-disable-line no-template-curly-in-string
	removeUserFromGroupProduction:
		'/admin/users/production/${username}/group/${groupname}', // eslint-disable-line no-template-curly-in-string
	deleteUserProduction: '/admin/users/production/${username}' // eslint-disable-line no-template-curly-in-string
};

const cognitoSecurityGroups = [
	'savant-enterprise',
	'sales',
	'savant-premium-plus',
	'savant-premium',
	'savant-free',
	'savant-ni-enterprise',
	'sales-ni',
	'savant-ni-premium-plus',
	'savant-ni-premium',
	'savant-ni-free',
	'savant-steel-enterprise',
	'sales-steel',
	'savant-steel-premium-plus',
	'savant-steel-premium',
	'savant-steel-free'
];

const types = {
	cu: 'Copper',
	ni: 'Nickel',
	ti: 'Titanium',
	steel: 'Steel'
};

const countries = [
	'Afghanistan',
	'Åland Islands',
	'Albania',
	'Algeria',
	'American Samoa',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antarctica',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia',
	'Bonaire, Sint Eustatius and Saba',
	'Bosnia and Herzegovina',
	'Botswana',
	'Bouvet Island',
	'Brazil',
	'British Indian Ocean Territory',
	'Brunei Darussalam',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cabo Verde',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Cayman Islands',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Christmas Island',
	'Cocos Islands',
	'Colombia',
	'Comoros',
	'Democratic Republic of the Congo',
	'Congo',
	'Cook Islands',
	'Costa Rica',
	'Croatia',
	'Cuba',
	'Curaçao',
	'Cyprus',
	'Czechia',
	"Côte d'Ivoire",
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Eswatini',
	'Ethiopia',
	'Falkland Islands',
	'Faroe Islands',
	'Fiji',
	'Finland',
	'France',
	'French Guiana',
	'French Polynesia',
	'French Southern Territories',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Grenada',
	'Guadeloupe',
	'Guam',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Heard Island and McDonald Islands',
	'Holy See',
	'Honduras',
	'Hong Kong',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Ireland',
	'Isle of Man',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jersey',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'Korea (North)',
	'Korea (South)',
	'Kuwait',
	'Kyrgyzstan',
	'Lao',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macao',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Martinique',
	'Mauritania',
	'Mauritius',
	'Mayotte',
	'Mexico',
	'Micronesia',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Niue',
	'Norfolk Island',
	'Northern Mariana Islands',
	'Norway',
	'Oman',
	'Pakistan',
	'Palau',
	'Palestine',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Pitcairn',
	'Poland',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Republic of North Macedonia',
	'Romania',
	'Russian Federation',
	'Rwanda',
	'Réunion',
	'Saint Barthélemy',
	'Saint Helena, Ascension and Tristan da Cunha',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Martin',
	'Saint Pierre and Miquelon',
	'Saint Vincent and the Grenadines',
	'Samoa',
	'San Marino',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Sint Maarten',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Georgia and the South Sandwich Islands',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Svalbard and Jan Mayen',
	'Sweden',
	'Switzerland',
	'Syrian Arab Republic',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tokelau',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Turks and Caicos Islands',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'United States Minor Outlying Islands',
	'United States of America',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Venezuela',
	'Viet Nam',
	'Virgin Islands',
	'Wallis and Futuna',
	'Western Sahara',
	'Yemen',
	'Zambia',
	'Zimbabwe'
];

const sandbox = [
	{
		val: 0,
		name: 'Normal'
	},
	{
		val: 1,
		name: 'Trial'
	},
	{
		val: 2,
		name: 'Unknown'
	}
];

export default {
	apiEndpoints,
	countries,
	cognitoSecurityGroups,
	types,
	sandbox,
	...config
};
